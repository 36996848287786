<template>
<v-container fluid>
  <v-row class="d-flex justify-space-between">
    <v-col>
      <h1 class="display-1 font-weight-bold mx-1">
        Browse Products <small>{{totalProducts}} items</small>
        <v-btn color="success" class="ml-4" :disabled="!filtersChanged" @click="saveSearch">Save Search</v-btn>
        <v-menu min-width="300px" left offset-y color="white" opacity="1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" class="ml-4" v-bind="attrs" v-on="on">
              Saved Searches
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-card>
          <v-virtual-scroll :items="recentSearches" :item-height="60" height="300">
            <template v-slot="{ index, item }">
              <v-list-item :key="index" @click="loadSearch(item)" color="rgba(255,255,255,1)">
                <v-list-item-content color="rgba(255,255,255,1)">
                  <v-list-item-title>
                    {{ formatFilter(item) }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-menu open-on-hover left offset-x>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" v-bind="attrs" v-on="on">
                        <v-icon>mdi-information</v-icon>
                      </v-btn>
                    </template>
                    <v-card max-width="200" color="rgba(255,255,255,1)">
                      <v-card-text>
                        {{ formatFilter(item) }}
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
          </v-card>
          <!--
          <v-list>
            <v-list-item class="mb-2 pb-2" style="border-bottom: 1px solid silver" v-for="(item, index) in recentSearches" :key="index" @click="loadSearch(item)">
              {{formatFilter(item)}}
            </v-list-item>
          </v-list> -->
        </v-menu>
        <v-btn :disabled="totalProducts > 250" v-if="isAdmin" color="success" class="ml-4" @click.stop="downloadCatalog(products)">
          Catalog PDF (max 250)
          <v-icon class="ml-4">
            mdi-download
          </v-icon>
        </v-btn>
        <!-- <v-menu v-if="isCustomer" min-width="300px" left offset-y color="white" opacity="1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="success" class="ml-4" v-bind="attrs" v-on="on">
              Export
              <v-icon right>
                mdi-chevron-down
              </v-icon>
            </v-btn>
          </template>
          <v-card>
          <v-list>
              <v-divider></v-divider>
              </v-list>
          </v-card>

          <v-list>
            <v-list-item class="mb-2 pb-2" style="border-bottom: 1px solid silver" v-for="(item, index) in recentSearches" :key="index" @click="loadSearch(item)">
              {{formatFilter(item)}}
            </v-list-item>
          </v-list>
        </v-menu> -->
        </h1>
    </v-col>
  </v-row>
  <v-row class="ma-0">
      <template v-if="processing">
      <v-container class="px-0">
        <v-progress-linear
          color="light-blue"
          height="10"
          :value="progress"
          striped
        ></v-progress-linear>
      </v-container>
      </template>
      <v-col class="pa-0">
      <v-radio-group
        class="pl-8"
        v-model="sort"
        row
      >
        <template slot="label">
          <h3>Sort by: </h3>
        </template>
        <v-radio
          v-if="!isCustomer"
          label="SKU"
          value="SKU"
        ></v-radio>
        <v-radio
          v-else
          label="Category"
          value="SKU"
        ></v-radio>
        <v-radio
          label="Title"
          value="TITLE"
        ></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
  <v-row class="mt-0">
    <v-col :key="$index" v-for="(item, $index) in products"  xl= "2" lg="3" sm="4" cols="12" class="pt-0 d-flex flex-column">
      <v-card
      :id="`item${$index}`"
      :key="$index"
      class="mx-2 my-2 d-flex flex-column flex-grow-1 fill-height"
    >
      <v-card-text class="d-flex flex-column">
        <!-- <v-badge
          :value="isNew(item.truncatedDate)"
          bordered
          color="green"
          overlap
          content="new"
        > -->
          <v-img contain :aspect-ratio="1/1" class="v-clickable" @click.native="showProduct($index)" :src="`${imageUrl}/300px/${item.variants[0].productImages[0]}`" />
        <!-- </v-badge> -->
        <div class="mt-4">
          {{item.sku }} {{ item.title }} by {{ item.artist }}
        </div>
        <div class="pt-2">
          {{ ["GICO","GICL"].includes(item.sku.split('-')[0]) ? item.category : item.variants[0].variantName }}
        </div>
      </v-card-text>
        <v-spacer></v-spacer>
        <v-card-text class="pt-0">
        <div>
          <strong>Price:</strong> -  {{getPriceRange(item)}}
        </div>
        </v-card-text>
        <template v-if="isCustomer" class="d-flex">
          <add-order-item v-if="item.variants.length == 1" :item="{sku:item.variants[0].sku,unitPrice:item.variants[0].unitPrice,minQty:item.minQty,multQty:item.multQty}" :cart="cart" />
          <add-variants-menu v-if="item.variants.length > 1" @addProduct="addProductsToCart" :item="item" :cart="cart" />
        </template>
    </v-card>
    </v-col>
  </v-row>
  <infinite-loading ref="infiniteLoading" :identifier="infiniteId" @infinite="infiniteHandler">
    <span slot="no-results">
      <p />
      <span class="your-customize-class"><p></p>Couldn't load any results. Try resetting the search.</span>
    </span>
    <span slot="no-more">That's all folks.</span>
  </infinite-loading>
  <product-dialog v-if="openDialog" :key="selectedItem" v-model='openDialog' :item='selectedItem' @loadMore="loadMore" @close="closeDialog" />
</v-container>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading'
import ProductApi from '@/api/admin/product'
import ProductDialog from '@/views/Common/Products/ProductDialog.vue'
import ImageVariant from '@/components/ImageVariant.vue'
import AddOrderItem from '@/components/AddOrdersMenu.vue'
import AddVariantsMenu from '@/components/AddVariantsMenu.vue'
import { mapState, mapGetters } from 'vuex'
import { hexToRGB } from '@/utilities/colour'
import formatFiltersAsString from '@/utilities/formatFiltersAsString'

const productSortFields = {
  RECENT: { "created.createdAt": -1, artist: 1, skuSequence: 1},
  SKU: { category: 1, artist: 1, skuSequence: 1},
  CATEGORY: { category: 1 },
  TITLE: { title: 1 }
}
export default {
  components: {
    InfiniteLoading,
    ProductDialog,
    ImageVariant,
    AddOrderItem,
    AddVariantsMenu
  },
  data () {
    return {
      sort: 'TITLE',
      showExport: false,
      menu: false,
      openDialog: false,
      selectedItem: 0,
      processing: false,
      progress: 0,
      headers: [
        {
          text: 'Artist',
          align: 'start',
          sortable: false,
          value: 'artist'
        },
        { text: 'Title', value: 'title' },
        { text: 'Category', value: 'category' }
      ],
      imageUrl: process.env.VUE_APP_IMG_URL,
      category: [],
      infiniteId: +new Date()
    }
  },
  computed: mapState({
    ...mapGetters('filters', ['filters']),
    ...mapGetters({
      recentSearches: 'filters/recentSearches',
      filtersChanged: 'filters/filtersChanged'
    }),
    ...mapGetters([
      'isLoggedIn',
      'isCustomer',
      'isAdmin'
    ]),
    // picks: state => state.products.picks,
    // searchtext: state => state.filters.searchText,
    // onlyPicks: state => state.filters.onlyPicks,
    xfilters: state => state.filters.all,
    // colours: state => state.colours.colours,
    products: state => state.products.products,
    totalProducts: state => state.products.totalProducts,
    page: state => state.products.page,
    hasMoreProducts: state => state.products.hasNextPage,
    picksFilter () {
      return this.onlyPicks ? { picks: this.picks } : {}
    },
    cart () { return this.$store.getters['cart/cart'] },
  }),
  mounted: async function () {
      const HOST = process.env.NODE_ENV === 'development' ? `ws://localhost:${process.env.VUE_APP_PORT}` : location.origin.replace(/^http/, 'ws')
      const connection = await new WebSocket(HOST)
      connection.addEventListener('open', function (event) {
        connection.send(12345)
      })
      connection.onmessage = (event) => {
        const payload = JSON.parse(event.data)
        switch (payload.message) {
          case 'progress':
            this.progress = payload.data
            break
          case 'complete':
            this.processing = false
            this.uploadSuccess = true
            break
          default:
            break
        }
        // if (event.data.message === 'complete')
        //   this.progress = event.data
      }
    await this.$store.commit('products/clearProducts')
  },
  watch: {
    onlyPicks: {
      handler () {
        this.$store.commit('products/clearProducts')
        this.infiniteId += 1
      }
    },
    filters: {
      deep: true,
      handler () {
        this.$store.commit('products/clearProducts')
        this.infiniteId += 1
      }
    },
    sort: {
      deep: true,
      handler () {
        this.$store.commit('products/clearProducts')
        this.infiniteId += 1
      }
    }
  },
  methods: {
    toggleDrawer () {
      this.$emit('toggleDrawer')
    },
    isNew (date) {
      return date ? new Date(date) < new Date() : false
    },
    addProductsToCart (items) {
      this.$store.dispatch('cart/updateCartItems', items)
    },
    RGBAcolour (hex) {
      return `rgba(${hexToRGB(hex)}, 0.7)`
    },
    scrollTo (hash) {
      const top = document.getElementById(hash).offsetTop
      window.scrollTo(0, top - 30)
    },
    closeDialog (element) {
      this.scrollTo(`item${element}`)
      if (this.products.length >= this.totalProducts) {
        this.$refs.infiniteLoading.stateChanger.complete()
      }
      this.openDialog = false
    },
    loadMore () {
      this.infiniteHandler()
    },
    showProduct (index) {
      this.openDialog = true
      this.selectedItem = index
    },
    isPick (id) {
      return this.picks.find(item => item === id)
    },
    getPriceRange (item) {
      const prices = item.variants.map(variant => variant.unitPrice)
      const min = prices ? Math.min(...prices) : 0
      const max = prices ? Math.max(...prices) : 0
      const a = min > 0 ? `£${min.toFixed(2)}` : undefined
      const b = max > min ? ` - £${max.toFixed(2)}` : undefined
      return a && b ? a + b : a
    },
    setPick (id) {
      const isPick = this.picks.findIndex(item => item === id)
      const currentPicks = [...this.picks]
      isPick === -1 ? currentPicks.push(id) : currentPicks.splice(isPick, 1)
      this.$store.commit('products/setPicks', currentPicks)
      if (this.onlyPicks) {
        this.$store.commit('products/clearProducts')
        this.infiniteId += 1
      }
      this.$emit('picks', this.picks.length)
    },
    async downloadCatalog() {
      const HOST = process.env.NODE_ENV === 'development' ? `ws://localhost:${process.env.VUE_APP_PORT}` : location.origin.replace(/^http/, 'ws')
      const connection = await new WebSocket(HOST)
      connection.addEventListener('open', function (event) {
        connection.send(12345)
      })
      connection.onmessage = (event) => {
        console.log(event)
        const payload = JSON.parse(event.data)
        switch (payload.message) {
          case 'progress':
            this.progress = payload.data
            break
          case 'complete':
            this.processing = false
            this.uploadSuccess = true
            break
          default:
            break
        }
        // if (event.data.message === 'complete')
        //   this.progress = event.data
      }
      this.processing = true
      ProductApi.getCatalogPDF(this.products).then((results) => {
      })
    },
    infiniteHandler ($state) {
      const params = {
        filter: this.filters,
        picksFilter: this.picksFilter,
        page: this.page,
        infiniteContext: $state,
        sort: productSortFields[this.sort]
      }
      this.$store.dispatch('products/getAllProducts', params)
    },
    async saveSearch () {
      await this.$store.dispatch('filters/saveSearches', JSON.stringify(this.filters))
    },
    async loadSearch (search) {
      await this.$store.commit('filters/resetFilters')
      await this.$store.commit('filters/loadFilters', search)
      window.location.reload()
    },
    formatFilter: formatFiltersAsString
  }
}
</script>
<style scoped>
</style>
